<script>
import Loading from "~/components/molecules/Loading.vue";
import { useLoaderStore } from "~~/store/loader";

export default {
  components: { Loading },
  setup() {
    const loaderStore = useLoaderStore;
    return {
      loaderStore,
    };
  },
  computed: {
    loader() {
      return this.loaderStore.isLoading;
    },
  },
};
</script>

<template>
  <div class="min-w-screen min-h-screen bg-white font-serif">
    <NuxtPage />
    <Loading v-if="loader" />
  </div>
</template>
